export default function TextField(theme) {
    return {
      MuiTextField: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        }
      }
    };
  }
  